import {useToast} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {rgba} from 'polished';
import {useState} from 'react';
import {RiArrowGoBackFill, RiCheckFill} from 'react-icons/ri';

import {files, images} from '../../../assets';
import {Image} from '../../../components/Image';
import importHweb3dEngine from '../../../sdk/ImportHweb3dEngine';
import {useAuth} from '../../auth/hooks/useAuth';
import {modelSave} from '../api/modelSave';
import {STEPS} from '../constants';
import {useHomeStore} from '../store';

function download(blob, filename) {
  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.appendChild(link); // Firefox workaround, see #6594
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}
window.downloadGlbFile = async () => {
  const Hweb3dEngine = await importHweb3dEngine();
  Hweb3dEngine.exporterGlb(
    (data) => {
      download(data.glb, `${new Date().getTime()}.glb`);
    },
    (err) => {
      // 获取模型数据失败
      console.log('exporterGlb-error', err);
    }
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 80px;
  > button {
    margin: 0 8px;
  }
`;

const Button = styled.button`
  min-width: 68px;
  height: 48px;
  padding: 0 20px;
  background: ${rgba('white', 0.2)};
  border: 2px solid #ffffff;
  border-radius: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  z-index: 1;
  backdrop-filter: blur(2px);
  white-space: nowrap;
  font-size: 16px;
  line-height: unset;
  font-weight: bold;

  &:disabled {
    opacity: 0.6;
  }
`;

function save(blob, filename) {
  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.appendChild(link); // Firefox workaround, see #6594
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();

  // URL.revokeObjectURL( url ); breaks Firefox...
}

export function ActionButtons() {
  const {step, tutorial, drawTree, enterExhibition} = useHomeStore();
  const toast = useToast();
  const {isAuthorized, login, userInfo} = useAuth();
  const [isModelSaving, setIsModelSaving] = useState(false);

  const isShowTutorial = step.key === STEPS.tutorial.key && tutorial.hasEnd;
  const isShowDrawCheck = step.key === STEPS.draw2D.key;
  const isShowThatsIt = step.key === STEPS.check2D.key;
  const isShowEnterNewWorld = step.key === STEPS.check3D.key;
  const isShowContinue = step.key === STEPS.linjian.key;

  function handleClickRedraw() {
    location.assign('/?isRedraw=true');
  }

  return (
    <Container className='ActionButtons'>
      {isShowTutorial && (
        <>
          <Button onClick={() => tutorial.clickReplay()}>再看一遍</Button>
          <Button onClick={() => tutorial.clickFinish()}>我知道了</Button>
        </>
      )}
      {isShowDrawCheck && (
        <>
          <Button
            onClick={async () => {
              const Hweb3dEngine = await importHweb3dEngine();
              Hweb3dEngine.rescind();
            }}
          >
            <RiArrowGoBackFill size={24} />
          </Button>
          <Button
            onClick={async () => {
              const Hweb3dEngine = await importHweb3dEngine();
              Hweb3dEngine.show2dTree(
                {showAnimation: true},
                () => {
                  drawTree.clickCheckDraw();
                },
                (e) => {
                  e.code === 1 &&
                    toast({
                      status: 'warning',
                      title: '请画完三笔',
                    });
                }
              );
            }}
          >
            <RiCheckFill size={24} />
          </Button>
        </>
      )}
      {isShowThatsIt && (
        <>
          <Button onClick={handleClickRedraw}>重画</Button>
          <Button
            disabled={isModelSaving}
            onClick={async () => {
              if (!isAuthorized) await login();
              const Hweb3dEngine = await importHweb3dEngine();
              Hweb3dEngine.creat3dTree(async () => {
                try {
                  setIsModelSaving(true);

                  const {img, json, json2d, obj} =
                    await Hweb3dEngine.save3dtree();
                  const {treeskin, leafmodel, leafskin} = drawTree.material;
                  Hweb3dEngine.exporterGlb(
                    async (data) => {
                      setIsModelSaving(true);
                      // save(data.glb, `glb${new Date().getTime()}.glb`);
                      const formData = new FormData();
                      formData.append('treeskinid', treeskin.id);
                      formData.append('leafmodelid', leafmodel.id);
                      formData.append('leafskinid', leafskin.id);
                      formData.append('img', new File([img], 'img.jpg'));
                      formData.append('json', new File([json], '3d.json'));
                      formData.append('json2d', new File([json2d], '2d.json'));
                      formData.append('obj', new File([obj], 'obj.json'));
                      formData.append(
                        'glb',
                        new File([data.glb], `glb${new Date().getTime()}.glb`)
                      );
                      await modelSave(formData);
                      drawTree.clickThatsIt();
                      fetch(files.ina); // 预加载ina模型
                    },
                    (err) => {
                      // 获取模型数据失败
                      console.log('exporterGlb-error', err);
                    }
                  );

                  // const {treeskin, leafmodel, leafskin} = drawTree.material;

                  // const formData = new FormData();
                  // formData.append('treeskinid', treeskin.id);
                  // formData.append('leafmodelid', leafmodel.id);
                  // formData.append('leafskinid', leafskin.id);
                  // formData.append('img', new File([img], 'img.jpg'));
                  // formData.append('json', new File([json], '3d.json'));
                  // formData.append('json2d', new File([json2d], '2d.json'));
                  // formData.append('obj', new File([obj], 'obj.json'));

                  // await isModelReady();
                } catch {
                  toast({status: 'error', title: '模型保存失败，请重试'});
                } finally {
                  setIsModelSaving(false);
                }
              });
            }}
          >
            {isModelSaving && <Image size={20} src={images.loading2} />}
            {isModelSaving ? '努力生长中' : '就是它了'}
          </Button>
        </>
      )}
      {isShowEnterNewWorld && (
        <>
          <Button onClick={handleClickRedraw}>重画</Button>
          <Button
            onClick={async () => {
              if (!isAuthorized) await login();
              if (userInfo.userName && userInfo.avatar) {
                if (userInfo.treeModel) {
                  toast({
                    title: '您之前已经种过树了，现在您可以直接进入',
                    status: 'success',
                  });
                }
                // navigate('/exhibition?skip=true', {replace: true});

                enterExhibition.enterSkip();
                return;
              }

              drawTree.clickEnterNewWorld();
            }}
          >
            进入新世界
          </Button>
        </>
      )}
      {isShowContinue && (
        <>
          <Button onClick={handleClickRedraw}>重画</Button>
          <Button onClick={() => drawTree.clickContinue()}>继续</Button>
        </>
      )}
    </Container>
  );
}
