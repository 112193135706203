import styled from '@emotion/styled';
import {size} from 'polished';

import {files} from '../../../assets';
import {MEDIA} from '../../../constants/media';

const Container = styled.div`
  position: absolute;
  //inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  margin-bottom: 0;
  ${size(400)}
  transform: translate(-200px, -90px);
  pointer-events: none;

  ${MEDIA.sm} {
    transform: translate(-120px, -40px);
  }
`;

export function Ina() {
  return (
    <Container className='Ina'>
      <model-viewer
        autoplay
        animation-name='Idle'
        camera-orbit='-20deg auto 100%'
        exposure='1.4'
        max-camera-orbit='auto auto 500%'
        shadow-intensity='1.8'
        shadow-softness='1.2'
        src={files.ina}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </Container>
  );
}
