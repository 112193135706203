import styled from '@emotion/styled';

import {useEffectOnce} from '../../../hooks/useEffectOnce';
import importHweb3dEngine from '../../../sdk/ImportHweb3dEngine';
import {get3dMaterial} from '../api/get3dMaterial';
import {useHomeStore} from '../store';
const Container = styled.div`
  flex: 1;
  position: fixed;
  top: 0;
`;

export function DrawTree() {
  const {drawTree} = useHomeStore();

  useEffectOnce(() => {
    (async () => {
      const Hweb3dEngine = await importHweb3dEngine();

      get3dMaterial().then((data) => {
        drawTree.setMaterial(data);
        // eslint-disable-next-line no-unused-vars
        const {leafskin, treeskin, leafmodel, leafimgs} = data;
        Hweb3dEngine.showPaper2d({
          viewId: 'viewBox',
          // leafNum: 400,
          treeskin: treeskin?.file,
          leafskin: leafskin?.file,
          leafmodel: leafmodel?.file,
        });
      });
    })();
  });

  return <Container className='DrawTree' id='viewBox' />;
}
