import {css, Global} from '@emotion/react';
import styled from '@emotion/styled';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import {VideoPlayer} from '@videojs-player/react';
import React, {useState} from 'react';
import {useEffect, useRef} from 'react';
import {useCallback} from 'react';

import {files} from '../../../assets';
import {useCustomMedia} from '../../../hooks/useCustomMedia';
import {useHomeStore} from '../../home/store';

import 'video.js/dist/video-js.css';
const Video = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Css = css`
  .video-js {
    background: none;
  }
  .vjs-tech {
    object-fit: cover;
  }
`;
export const EnterVideo = React.forwardRef(
  ({skipLinjian = 1, autoplay = false, onEnded, ...rest}, ref) => {
    const {enterExhibition} = useHomeStore();
    const forceUpdate = useForceUpdate();
    const {isSmall} = useCustomMedia();
    const domRef = useRef();
    const isEnd = useRef(false);
    const [duration, setDuration] = useState(-1);
    ref = domRef;
    const isEnterExhibition = enterExhibition.show;

    const onLoad = useCallback((e) => {
      setDuration(e.target.player.duration());
      if (autoplay) {
        ref.current.play();
      }
      if (ref.current && skipLinjian) {
        if (skipLinjian === 1) {
          ref.current.currentTime(7.2);
        } else if (skipLinjian === 2) {
          ref.current.currentTime(9);
        }
      } else {
        ref.current.currentTime(0);
      }
    }, []);

    useEffect(() => {
      if (ref.current && isEnterExhibition && ref.current.paused()) {
        ref.current.play();
      }
    }, [ref.current, isEnterExhibition]);

    useEffect(() => {
      if (isEnd.current) {
        ref.current.currentTime(duration);
      }
    }, [isSmall, duration]);

    return (
      <>
        <VideoPlayer
          fill
          muted
          paused
          playsInline
          preload
          className='vjs-tech'
          controls={false}
          src={isSmall ? files.linjianMobileVideo : files.linjianVideo}
          onEnded={() => {
            isEnd.current = true;
            onEnded && onEnded();
          }}
          onLoadedMetadata={onLoad}
          onMounted={(e) => {
            ref.current = e.player;
            forceUpdate();
          }}
          onPlay={() => {
            enterExhibition.enter();
          }}
        ></VideoPlayer>
        <Global styles={[Css]}></Global>
      </>
    );
  }
);
EnterVideo.displayName = 'EnterVideo';
