import {sleep} from '../../../utils/sleep';

const canvas = document.createElement('canvas');

export function imageToPngBase64(src, ratio = 1) {
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = src;
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      img.width *= ratio;
      img.height *= ratio;
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext('2d');
      sleep(0).then(() => {
        context.drawImage(img, 0, 0, img.width, img.height);
        const base64 = canvas.toDataURL('image/png', 1);
        res(base64);
      });
    };
    img.onerror = rej;
  });
}
