import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {size} from 'polished';

import {files} from '../../../assets';
import {MEDIA} from '../../../constants/media';
import {STEPS} from '../constants';
import {useHomeStore} from '../store';

const Container = styled.div`
  position: absolute;
  pointer-events: none !important;
  ${size(330)}
  //inset: 0;
   top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transform: translate(-120px, 120px);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;

  ${MEDIA.sm} {
    ${size(250)}
    transform: translate(50px, 90px);
  }
`;

export function Ina() {
  const {step} = useHomeStore();

  let animationName = 'Idle';

  switch (step.key) {
    // case STEPS.linjian.key: {
    //   animationName = 'Greeting';
    //   break;
    // }
    case STEPS.yourname.key: {
      animationName = 'Greeting';
      break;
    }
    case STEPS.noface.key: {
      animationName = 'Talk';
      break;
    }
    case STEPS.nftCard.key: {
      animationName = 'Yes';
      break;
    }
    default: {
      animationName = 'Idle';
    }
  }

  const showInaOnCard = step.key !== STEPS.linjian.key;

  return (
    <Container
      className='Ina'
      css={
        showInaOnCard &&
        css`
          ${size(450)};
          transform: translate(-300px, 30px);

          ${MEDIA.sm} {
            ${size(300)}
            transform: translate(-110px, 190px);
          }
        `
      }
    >
      <model-viewer
        autoplay
        animation-name={animationName}
        camera-orbit={
          animationName === 'Talk'
            ? '-55deg auto 100%'
            : animationName === 'Yes'
            ? '-10deg auto 150%'
            : '-10deg auto 100%'
        }
        exposure='1.2'
        max-camera-orbit='auto auto 500%'
        shadow-intensity='2'
        src={files.ina}
        style={{
          position: 'absolute',
          margin: 'auto',
          width: '100%',
          height: '100%',
          ...(animationName === 'Yes' && {width: '150%', height: '150%'}),
        }}
      />
    </Container>
  );
}
