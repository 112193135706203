import Vivus from '../sdk/vivus.min';
import {sleep} from './sleep';

export function drawNoface(
  elementId = 'nonFacePortraitSvg',
  onDrawEnd,
  duration = 200
) {
  hideFills();

  const vi = new Vivus(
    elementId,
    {
      type: 'oneByOne',
      start: 'autostart',
      duration,
      delay: 0,
    },
    () =>
      showFills()
        .then(() => sleep(3000))
        .then(() => {
          if (onDrawEnd) onDrawEnd(vi);
        })
  );
}

export function drawNofaceInfinite(elementId) {
  drawNoface(elementId, (vi) => {
    vi.destroy();
    drawNofaceInfinite(elementId);
  });
}

function showFills(className = 'fillArea') {
  const fills = [...document.getElementsByClassName(className)];

  return new Promise((res) => {
    fills.forEach((item, i) => {
      setTimeout(() => {
        item.style.opacity = 1;
        res();
      }, 250 * i + 250);
    });
  });
}

function hideFills(className = 'fillArea') {
  const fills = [...document.getElementsByClassName(className)];

  fills.forEach((item) => {
    item.style.transition = 'none';
    item.style.opacity = 0;
    Promise.resolve().then(() => {
      item.style.transition = '.5s';
    });
  });
}
