import styled from '@emotion/styled';
import {size} from 'polished';

import {images} from '../../../assets';
import {MEDIA} from '../../../constants/media';
import {useGetUserModel} from '../api/useGetUserModel';

const Container = styled.div`
  position: absolute;
  ${size(600)}

  margin: auto;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  margin: 200px 0 0 -400px;
  &::after {
    content: '';
    display: block;
    ${size(140)}
    background: url(${images.home.grass}) no-repeat center bottom/contain;
    position: absolute;
    top: 50%;

    left: 50%;
    margin: 0px 0 0 0px;
    transform: translate(-50%, -50%) rotate(-20deg);
    ${MEDIA.sm} {
      margin: 0 0 0 0;
      transform: translate(-50%, -50%) rotate(-20deg);
    }
  }

  &::before {
    content: '';
    display: block;
    ${size(200)}
    background: url(${images.home.grass}) no-repeat center bottom/contain;
    position: absolute;

    top: 50%;

    left: 50%;
    margin: 0px 0 0 0px;
    transform: translate(-50%, -50%);
    ${MEDIA.sm} {
      margin: 0 0 0 0;
      transform: translate(-50%, -50%);
    }
  }

  ${MEDIA.sm} {
    ${size(500)}
    margin: 0 0 0 -60px;

    top: 68%;
    transform: translate(-50%, -50%);
  }
`;

export function Tree() {
  const {data} = useGetUserModel();

  if (!data) return null;

  return (
    <Container className='Tree'>
      <model-viewer
        autoplay
        camera-controls
        disable-zoom
        ar-status='not-presenting'
        camera-target='0m 0m 0m'
        exposure='1.2'
        id='tree'
        max-camera-orbit='auto 88deg'
        min-camera-orbit='auto 88deg'
        shadow-intensity='2'
        shadow-softness='1.4'
        src={data.file_glb}
        style={{
          position: 'absolute',
          margin: 'auto',
          width: '100%',
          height: '100%',
        }}
      />
    </Container>
  );
}
