import {Center, Flex} from '@chakra-ui/react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {AnimatePresence, motion} from 'framer-motion';
import {cover} from 'polished';
import QRCode from 'qrcode';
import {useEffect, useRef, useState} from 'react';
import {RiDownload2Fill} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';
import useSWR from 'swr';

import {images} from '../../../assets';
import {Image} from '../../../components/Image';
import {MEDIA} from '../../../constants/media';
import {drawNoface} from '../../../utils/drawNoface';
import {useGetNftShare} from '../api/useGetNftShare';
import {useHomeStore} from '../store';
import {imageToPngBase64} from '../utils/imageToPngBase64';
import {NFTCardSvg} from './NFTCardSvg';
import '../../../assets/fonts/trajanpro/stylesheet.css';

const Container = styled(Center)`
  ${cover()}
  background-color: rgba(0, 0, 0, 0.4);
  flex-direction: column;
  gap: 40px;
  z-index: 1;
  perspective: 1000px;
`;

const BlurBg = styled.div`
  ${cover()}
  backdrop-filter: blur(20px);
  z-index: 1;
`;

const MotionContainer = styled(motion.div)`
  position: relative;
  transform-style: preserve-3d;

  width: 300px;
  height: 475px;

  ${MEDIA.sm} {
    width: 300px;
    height: 475px;
  }
`;

const Card = styled.div`
  position: absolute;
  //inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  border-radius: 12px !important;
  overflow: hidden !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: #e1d0c1;
  cursor: pointer;
  backface-visibility: hidden;
  user-select: none;
`;

const CardBack = styled(Card)`
  background-image: none;
  background-color: #e1d0c1;
  transform: rotateY(-180deg);

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(${images.home.contractCardBack}) no-repeat center/cover;
    transition: 1s;
    border-radius: 12px !important;
    overflow: hidden !important;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    left: 20px;
    width: 10px;
    background-color: white;
    transition: 1s;
    filter: blur(10px);
  }
`;

const BackHashText = styled.p`
  font-size: 60px;
  font-family: 'Trajan Pro';
  color: #cbb9a9;
  position: absolute;
  top: 10%;
  left: 10%;
  transition: 1s;

  // ${MEDIA.sm} {
  //   font-size: 40px;
  // }
`;

const NofaceBox = styled.div`
  ${cover()}
  transform: translate(4%, 8%) scale(1.2);
  transition: 0.4s;

  svg {
    width: 100% !important;
    height: 100% !important;
    background-color: transparent !important;

    .fillArea {
      opacity: 0;
    }

    .svg_color_bg {
      display: none;
    }
  }
`;

// const CardHash = styled.div`
//   width: 32px;
//   background: #000000;
//   opacity: 0.8;
//   margin-left: 35px;
//   position: relative;
//   flex-shrink: 0;

//   ${MEDIA.sm} {
//     margin-left: 20px;
//   }
// `;

// const CardHashText = styled(Text)`
//   font-size: 14px;
//   font-weight: 500;
//   color: #917766;
//   position: absolute;
//   bottom: 14px;
//   left: 15px;
//   transform-origin: left center;
//   transform: rotate(-90deg) scale(0.7);
//   white-space: nowrap;
//   line-height: initial;
//   display: flex;
//   align-items: center;
//   gap: 10px;

//   ${MEDIA.sm} {
//     font-size: 12px;
//   }
// `;

// const Content = styled(Flex)`
//   flex-direction: column;
//   padding: 24px 20px;
//   flex: 1;
//   gap: 30px;
//   padding-top: 120px;

//   ${MEDIA.sm} {
//     padding: 24px 10px;
//     padding-top: 80px;
//     gap: 20px;
//   }
// `;

// const Avatar = styled.div`
//   flex-shrink: 0;
//   width: 60px;
//   height: 72px;
//   border: 2px solid #e1d0c1;
//   border-radius: 8px;
//   position: relative;
//   box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
//   margin: 0 auto;
//   overflow: hidden;

//   &::after {
//     content: '';
//     ${cover()}
//     background: url(${images.home.avatarTexture}) no-repeat center/cover,
//       rgba(0, 0, 0, 0.2);
//   }

//   ${MEDIA.sm} {
//     width: 40px;
//     height: 48px;
//   }
// `;

// const LabelText = styled(Text)`
//   font-size: 14px;
//   font-weight: 500;
//   color: #917766;

//   ${MEDIA.sm} {
//     font-size: 12px;
//   }
// `;

// const ContentText = styled(Text)`
//   font-weight: bold;
//   color: #401d08;

//   ${MEDIA.sm} {
//     font-size: 12px;
//   }
// `;

const Button = styled.button`
  width: 140px;
  height: 40px;
  background: linear-gradient(315deg, #77dcbc 0%, #14cccc 100%);
  box-shadow: 0px 0px 10px rgba(69, 77, 77, 0.1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: initial;

  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  gap: 4px;
`;

function hideFills(doc, className = 'fillArea') {
  const fills = [...doc.getElementsByClassName(className)];

  fills.forEach((item) => {
    item.style.transition = 'none';
    item.style.opacity = 0;
  });
}

export function NFTCard({userid, sign}) {
  const {
    yournameNoface: {nofaceRaw, setNofaceRaw},
  } = useHomeStore();
  const {data} = useGetNftShare({userid, sign});
  const isSharedPage = !!(userid && sign);
  const isLoading = !data;
  const [isShowBack, setIsShowBack] = useState(true);
  const [isNofaceAniEnd, setIsNofaceAniEnd] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isClickedContinue, setIsClickedContinue] = useState(false);
  const [nofaceAvatarBase64, setNofaceAvatarBase64] = useState();
  const [qrcodeBase64, setQrcodeBase64] = useState();
  const cardSvgRef = useRef();

  const navigate = useNavigate();

  const SVGRes = useSWR(data?.NFTsvg || '');
  // deal nofaceRaw id
  useEffect(() => {
    if (SVGRes.data) {
      let myNofaceRaw = SVGRes.data.replace(
        'nonFacePortraitSvg',
        'nonFacePortraitSvg2'
      );
      const domParser = new DOMParser();
      const doc = domParser.parseFromString(myNofaceRaw, 'text/xml');
      hideFills(doc);
      const serializer = new XMLSerializer();
      myNofaceRaw = serializer.serializeToString(doc);

      setNofaceRaw(myNofaceRaw);
    }
  }, [SVGRes.data]);

  // set base64
  useEffect(() => {
    if (data) {
      imageToPngBase64(data.NFTsvg).then(setNofaceAvatarBase64);
      QRCode.toDataURL(
        `${location.origin}/nftShared?userid=${data?.userid}&sign=${data?.sign}`,
        {
          rendererOpts: {quality: 1},
          width: 80,
          margin: 2,
          errorCorrectionLevel: 'L',
        },
        (_, url) => {
          setQrcodeBase64(url);
        }
      );
    }
  }, [data]);

  // play noface animation when enter
  useEffect(() => {
    let timer;
    if (nofaceRaw) {
      timer = setTimeout(() => {
        drawNoface('nonFacePortraitSvg2', () => {
          setIsNofaceAniEnd(true);
        });
      }, 0);
    }
    return () => clearTimeout(timer);
  }, [nofaceRaw]);

  // flip to front when get data and noface animation ends
  useEffect(() => {
    let timer;
    if (!isLoading && isNofaceAniEnd) {
      timer = setTimeout(() => {
        setIsShowBack(false);
      }, 1700);
    }
    return () => clearTimeout(timer);
  }, [isLoading, isNofaceAniEnd]);

  async function handleClickSave() {
    setIsSaving(true);
    try {
      await cardSvgRef.current.download();
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <AnimatePresence>
      {!isClickedContinue && (
        <motion.div
          exit={{opacity: 0, scale: 1.2}}
          style={{...cover()}}
          transition={{type: 'tween'}}
        >
          <BlurBg />

          <Container className='NftCard'>
            <MotionContainer
              animate={{
                rotateY: isShowBack ? '-180deg' : 0,
                y: 0,
                scale: 1,
              }}
              initial={{rotateY: '-180deg', y: 200, scale: 0}}
              transition={{
                type: 'spring',
              }}
              whileTap={{scale: 1.2}}
              onClick={() => {
                if (data) {
                  setIsShowBack(!isShowBack);
                }
              }}
            >
              <CardBack
                css={css`
                  &::after {
                    opacity: ${isNofaceAniEnd ? 1 : 0};
                  }
                  &::before {
                    opacity: ${isNofaceAniEnd ? 1 : 0};
                  }
                `}
              >
                <BackHashText style={{opacity: isNofaceAniEnd ? 1 : 0}}>
                  {data?.NFTNO}
                </BackHashText>
                <NofaceBox
                  dangerouslySetInnerHTML={{__html: nofaceRaw}}
                  style={{
                    ...(isNofaceAniEnd && {
                      transform: 'translate(4%, 14%) scale(1.2)',
                    }),
                  }}
                />
              </CardBack>

              <Card>
                {data && (
                  <NFTCardSvg
                    address={data?.contactAddress}
                    avatarBase64={nofaceAvatarBase64}
                    date={data?.createTime.split(' ')[0]}
                    hashTag={data?.NFTNO}
                    name={data?.NFTname}
                    owner={data?.createUser}
                    qrcodeBase64={qrcodeBase64}
                    ref={cardSvgRef}
                    style={{width: '100%', height: '100%'}}
                    time={data?.createTime.split(' ')[1]}
                    tradeHash={data?.TXHash}
                  />
                )}
              </Card>
            </MotionContainer>

            <motion.div
              animate={{opacity: isShowBack ? 0 : 1}}
              initial={{opacity: 0}}
            >
              {isSharedPage ? (
                <Button
                  target='_blank'
                  onClick={() => {
                    navigate('/about');
                  }}
                >
                  了解更多
                </Button>
              ) : (
                <Flex gap={3}>
                  <Button disabled={isSaving} onClick={handleClickSave}>
                    {isSaving ? (
                      <Image size={14} src={images.loading2} />
                    ) : (
                      <RiDownload2Fill />
                    )}
                    {isSaving ? '保存中' : '保存'}
                  </Button>
                  <Button onClick={() => setIsClickedContinue(true)}>
                    继续
                  </Button>
                </Flex>
              )}
            </motion.div>
          </Container>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
