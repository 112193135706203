import {css, Global} from '@emotion/react';

import {MEDIA} from './constants/media';
import {THEME} from './constants/theme';
import {isElectron} from './lib/isElectron';

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/assets.css';

// override basic style
const rebase = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  button,
  a {
    color: currentColor;
    text-decoration: none;
    border: none;
    cursor: pointer;

    &:not(:disabled):hover {
      opacity: 0.8;
    }
    &:not(:disabled):active {
      opacity: 0.4;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
`;

// global layout style
const layout = css`
  html,
  body,
  #root {
    height: 100%;
  }
`;

// global theming style
const themeing = css`
  html,
  body {
    font-size: 16px;
    font-family: PingFangSC, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
    color: ${THEME.colors.text};
    background-color: ${THEME.colors.background};
    overscroll-behavior: none;
    overflow: hidden;
  }

  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    background-clip: padding-box;
    border: 5px solid transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

// electron only
const electron = css`
  /* Draw a draggable area */
  body::before {
    content: '';
    display: block;
    position: fixed;
    height: 50px;
    pointer-events: none;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    -webkit-app-region: drag;
  }

  a {
    -webkit-user-drag: none;
  }
`;

// override model-viewer style
const overrideModelViewer = css`
  model-viewer {
    --poster-color: transparent;

    &::part(default-progress-bar) {
      visibility: hidden;
    }

    &::part(default-progress-mask) {
      display: none;
    }
  }
`;

// override anyweb
export const overrideAnyweb = css`
  #anyweb-iframe-mask .iframe-contain {
    height: 80%;
    width: 98%;
    max-width: 400px;
    border-radius: 10px;
    overflow: hidden;
  }

  #anyweb-iframe-mask {
    backdrop-filter: blur(18px);
  }

  #anyweb-custom-title {
    position: absolute;
    font-size: 24px;
    padding: 0px 10px;
    //inset: 30px 0 auto 0;
    top: 30px;
    right: 0;
    bottom: auto;
    left: 0;
    text-align: center;
    font-weight: bold;

    span {
      color: #559e12;
      padding: 0 4px;
    }

    ${MEDIA.sm} {
      font-size: 16px;
      padding: 0px 10px;
      //inset: 36px auto auto 0;
      top: 30px;
      right: auto;
      bottom: auto;
      left: 0;
    }
  }

  #anyweb-custom-footer {
    position: absolute;
    //inset: auto 0 0 0;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding: 20px 0;
    opacity: 0.4;

    ${MEDIA.sm} {
      font-size: 12px;
    }
  }
`;

export function GlobalStyles() {
  return (
    <Global
      styles={[
        rebase,
        layout,
        themeing,
        isElectron && electron,
        overrideModelViewer,
        overrideAnyweb,
      ]}
    />
  );
}
