import styled from '@emotion/styled';
import {size} from 'polished';
import {forwardRef} from 'react';

import {Image} from '../../../components/Image';
import {MEDIA} from '../../../constants/media';
import {useGetDigitalData} from '../../auth/api/useGetDigitalData';

const AvatarContainer = styled.div`
  ${size(100)}
  border: 4px solid white;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  ${MEDIA.sm} {
    ${size(64)}
  }
`;

export const UserAvatar = forwardRef((props, ref) => {
  const {data, error} = useGetDigitalData();
  return (
    <AvatarContainer
      as='a'
      className='UserAvatar'
      href='https://did.wonderina.com/user-center'
      ref={ref}
      {...props}
    >
      <Image
        src={
          data?.userIndex.avatar ||
          require('../../../assets/images/default_header.png')
        }
      />
    </AvatarContainer>
  );
});
UserAvatar.displayName = 'UserAvatar';
