import {CONFIG} from '../constants/config';

export const storage = {
  get(key) {
    return JSON.parse(
      window.localStorage.getItem(`${CONFIG.storagePrefix}${key}`)
    );
  },
  set(key, value) {
    window.localStorage.setItem(
      `${CONFIG.storagePrefix}${key}`,
      JSON.stringify(value)
    );
  },
  remove(key) {
    window.localStorage.removeItem(`${CONFIG.storagePrefix}${key}`);
  },
  getToken() {
    return this.get('token');
  },
  setToken(token) {
    this.set('token', token);
  },
  removeToken() {
    this.remove('token');
  },
};
