import {useToast} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {AnimatePresence, motion} from 'framer-motion';
import {cover} from 'polished';
import {useState} from 'react';
import {useEffect} from 'react';

import {images} from '../../../assets';
import {Page as DefaultPage} from '../../../components/Page';
import {MEDIA} from '../../../constants/media';
import {useGetDigitalData} from '../../auth/api/useGetDigitalData';
import {ActionButtons} from '../components/ActionButtons';
import {DrawTree} from '../components/DrawTree';
import {Header} from '../components/Header';
import {Ina} from '../components/Ina';
import {IndicateTitle} from '../components/IndicateTitle';
import {Linjian} from '../components/Linjian';
import {NFTCard} from '../components/NFTCard';
import {TutorialAnimation} from '../components/TutorialAnimation';
import {YournameNoface} from '../components/YournameNoface';
import {STEPS} from '../constants';
import {useHomeStore} from '../store';
const Page = styled(DefaultPage)`
  background: url(${images.home.bg}) no-repeat center/cover;
  color: white;
  ${MEDIA.sm} {
    background: url(${images.home.bgSmall}) no-repeat center/cover;
  }
  overflow: hidden;
`;

const MotionContainer = styled(motion.div)`
  ${cover()};
  display: flex;
`;

let isLoadGetData = true;

export function HomePage() {
  const {step, yournameNoface} = useHomeStore();
  const {data, error} = useGetDigitalData();
  const {enterExhibition, drawTree} = useHomeStore();
  const toast = useToast();
  const [isShowNftCard, setIsShowNftCard] = useState(true);

  const {treeModel, userName, avatar} = data?.userIndex ?? {};

  useEffect(() => {
    if (data && isLoadGetData) {
      if (treeModel) {
        // navigate('/exhibition?skip=true', {replace: true});
        // if (userName && avatar) {
        //   drawTree.clickEnterNewWorld();
        //   setIsShowNftCard(false);
        //   yournameNoface.clickCheckNoface();
        // } else {
        //   drawTree.clickEnterNewWorld();
        // }

        isLoadGetData = false;
      }
    }
  }, [data, enterExhibition, toast, treeModel]);
  return (
    <Page className='HomePage'>
      {step.stepNum <= 3 && <Header />}
      <IndicateTitle />

      {/* TutorialAnimation */}
      <AnimatePresence>
        {step.key === STEPS.tutorial.key && (
          <MotionContainer
            exit={{
              opacity: 0,
              scale: 1.1,
            }}
            style={{position: 'initial', flex: 1, display: 'flex'}}
            transition={{type: 'tween', ease: 'easeOut', duration: 0.6}}
          >
            <TutorialAnimation />
          </MotionContainer>
        )}
      </AnimatePresence>

      {/* DrawTree */}
      <AnimatePresence>
        {[STEPS.draw2D.key, STEPS.check2D.key, STEPS.check3D.key].includes(
          step.key
        ) && (
          <MotionContainer
            exit={{
              opacity: 0,
              scale: 1.1,
            }}
            transition={{type: 'tween', ease: 'easeOut', duration: 0.6}}
          >
            <DrawTree />
          </MotionContainer>
        )}
      </AnimatePresence>

      {/* Linjian */}
      {[
        STEPS.check3D.key,
        STEPS.linjian.key,
        STEPS.yourname.key,
        STEPS.noface.key,
        STEPS.nftCard.key,
      ].includes(step.key) && <Linjian />}

      {/* YournameNoface */}
      <AnimatePresence>
        {[STEPS.yourname.key, STEPS.noface.key, STEPS.nftCard.key].includes(
          step.key
        ) && (
          <MotionContainer
            animate={{opacity: 1, y: 0, scale: 1}}
            exit={{
              opacity: 0,
              y: -100,
              scale: 0.6,
            }}
            initial={{opacity: 0, y: 200, scale: 0.6}}
            transition={{type: 'spring', stiffness: 140, damping: 14}}
          >
            <YournameNoface />
          </MotionContainer>
        )}
      </AnimatePresence>

      {/* Ina */}
      <AnimatePresence>
        {[
          STEPS.linjian.key,
          STEPS.yourname.key,
          STEPS.noface.key,
          STEPS.nftCard.key,
        ].includes(step.key) && (
          <MotionContainer
            animate={{opacity: 1, x: 0, scale: 1}}
            exit={{
              opacity: 0,
              x: 0,
              scale: 0.6,
            }}
            initial={{opacity: 0, x: 0, scale: 0.8}}
            style={{pointerEvents: 'none'}}
            transition={{type: 'spring', stiffness: 140, damping: 14}}
          >
            <Ina />
          </MotionContainer>
        )}
      </AnimatePresence>

      {/* NFTCard */}
      {step.key === STEPS.nftCard.key && isShowNftCard && <NFTCard />}

      {/* ActionButtons */}
      <ActionButtons />
    </Page>
  );
}
