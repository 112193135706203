import {useToast} from '@chakra-ui/react';
import {useCopyToClipboard} from 'react-use';

export function useCopy() {
  const [, copyFn] = useCopyToClipboard();
  const toast = useToast();

  function copy(value) {
    copyFn(value);
    toast({
      status: 'success',
      title: '已拷贝',
      duration: 2000,
    });
  }

  return copy;
}
