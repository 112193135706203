import {stringifyUrl} from 'query-string';
import useSWR from 'swr';

export function useGetNftShare({userid, sign}) {
  return useSWR(
    stringifyUrl(
      {
        url: '/nftapi/share',
        query: {
          userid,
          sign,
        },
      },
      {skipEmptyString: true, skipNull: true}
    )
  );
}
