import {AppProviders} from './AppProviders';
import {AppRoutes} from './AppRoutes';

function App() {
  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  );
}

export default App;
