import {useMemo, useState} from 'react';

import {anyweb} from '../anyweb';
import {useGetDigitalData} from '../api/useGetDigitalData';
export function useAuth() {
  const {data, error, mutate} = useGetDigitalData();

  const [isLogining, setIsLogining] = useState(false);

  const isAuthorizing = useMemo(
    () => (!data && !error) || isLogining,
    [data, error, isLogining]
  );
  const isAuthorized = useMemo(() => !!(data && !error), [data, error]);

  const {
    userName,
    avatar,
    treeModel,
    applicationImage,
    applicationUrl,
    createTime,
    applicationName,
    applicationDesc,
  } = data?.userIndex ?? {};

  const userInfo = {
    userName,
    avatar,
    treeModel,
    applicationImage,
    applicationUrl,
    createTime,
    applicationName,
    applicationDesc,
  };

  async function login() {
    setIsLogining(true);
    await anyweb.login().finally(() => {
      setIsLogining(false);
    });
    mutate();
  }

  async function logout() {
    await anyweb.logout();
    mutate();
  }

  return {
    isAuthorizing,
    isAuthorized,
    login,
    logout,
    userInfo,
  };
}
