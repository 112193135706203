import useSWR from 'swr';

import {useGetPrivateParams} from '../hooks/useGetPrivateParams';

export function useGetSetup() {
  const {id, sid, invitecode} = useGetPrivateParams();

  const apiUrl =
    id && sid && invitecode
      ? `/nftapi/x/${id}/getTip/${sid}/${invitecode}`
      : '/nftapi/setup';

  return useSWR(apiUrl);
}
export function useExhibitionConfig() {
  return useSWR('/assets/configs/exhibition.json');
}
export function useGetSetupJson() {
  return useSWR('/assets/configs/setup.json');
}
