import {Flex, useToast} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {rgba, size} from 'polished';

import {images} from '../../../assets';
import {Image} from '../../../components/Image';
import {MEDIA} from '../../../constants/media';
import {THEME} from '../../../constants/theme';
import {useAuth} from '../../auth/hooks/useAuth';
import {useHomeStore} from '../store';

const ContainerWrapper = styled.header`
  height: 104px;
  background-color: ${rgba('#003322', 0.9)};
  backdrop-filter: blur(4px);
  position: relative;
  color: white;
  z-index: 1;

  ${MEDIA.sm} {
    height: 64px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  height: inherit;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
`;

const LogoButton = styled.button`
  position: absolute;
  left: 20px;

  ${MEDIA.sm} {
    display: none;
  }
`;
LogoButton.defaultProps = {};
LogoButton.defaultProps.onClick = () => window.location.assign('/');

const StepText = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${THEME.colors.whites[80]};

  ${MEDIA.sm} {
    font-size: 12px;
    transform: scale(0.83);
  }
`;

const StepCaptionText = styled.p`
  font-size: 18px;
  font-weight: 600;

  ${MEDIA.sm} {
    font-size: 16px;
    transform: scale(0.83);
  }
`;

const AvatarButton = styled.button`
  ${size(50)}
  border-radius: 50%;
  border: 4px solid #ffffff;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  background-color: white;
  overflow: hidden;
  position: absolute;
  right: 20px;

  ${MEDIA.sm} {
    ${size(40)}
    border: 3px solid #ffffff;
  }
`;

const LoginButton = styled.button`
  position: absolute;
  right: 20px;
`;

const Progress = styled.div`
  position: absolute;
  bottom: 0;
  height: 4px;
  width: 100%;
  background-color: #003322;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: inherit;
    transition: 0.6s;
    width: ${(props) => props.value}%;
    background-color: #00ca86;
  }
`;

export function Header() {
  const {step} = useHomeStore();
  const {isAuthorized, isAuthorizing, userInfo, login} = useAuth();
  const toast = useToast();
  const progressValue = (step.stepNum / 3) * 100;

  function toUserCenter() {
    if (userInfo.avatar && userInfo.userName) {
      window.location.href = 'https://did.wonderina.com/user-center/';
    } else {
      toast({
        status: 'warning',
        title: '个人中心暂不开放，需先完善个人信息！',
        duration: 3000,
      });
    }
  }

  return (
    <ContainerWrapper className='Header'>
      <Container>
        <LogoButton>
          <Image src={images.logo} width={188} />
        </LogoButton>

        <Flex alignItems='center' flexDir='column'>
          <StepText>第{step.stepNum}步</StepText>
          <StepCaptionText>{step.stepCaption}</StepCaptionText>
        </Flex>

        {isAuthorized ? (
          <AvatarButton onClick={toUserCenter}>
            <Image fit='cover' src={userInfo.avatar} />
          </AvatarButton>
        ) : (
          // <Popover>
          //   <PopoverTrigger>
          //     <AvatarButton>
          //       <Image fit='cover' src={userInfo.avatar} />
          //     </AvatarButton>
          //   </PopoverTrigger>
          //   <PopoverContent color='black' mr={2} width='auto'>
          //     <PopoverArrow ml={1} />
          //     <PopoverBody>
          //       <HStack>
          //         <Button color='red' size='sm' variant='link' onClick={logout}>
          //           退出登录
          //         </Button>
          //         <Center height='20px'>
          //           <Divider orientation='vertical' />
          //         </Center>
          //         <Button size='sm' variant='link'>
          //           用户中心
          //         </Button>
          //       </HStack>
          //     </PopoverBody>
          //   </PopoverContent>
          // </Popover>
          <LoginButton disabled={isAuthorizing} onClick={login}>
            登录
          </LoginButton>
        )}
      </Container>

      <Progress value={progressValue} />
    </ContainerWrapper>
  );
}
