import {Suspense} from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';

import {ExhibitionPage} from './features/exhibition/routes/ExhibitionPage';
import {HomeRoutes} from './features/home/routes';
import {lazyImport} from './utils/lazyImport';
const NFTSharedPage = lazyImport(
  () => import('./features/home/routes/NFTSharedPage'),
  'NFTSharedPage'
);
const About = lazyImport(() => import('./features/about/About'), 'About');
const NotFoundPage = lazyImport(
  () => import('./features/errors/routes/NotFoundPage'),
  'NotFoundPage'
);
const routes = [
  {
    path: '/',
    element: <HomeRoutes />,
  },
  {
    path: '/exhibition*',
    element: <ExhibitionPage />,
  },
  {
    element: <NFTSharedPage />,
    path: '/nftShared',
  },
  {
    element: <About />,
    path: '/about',
  },
  {
    element: <NotFoundPage />,
    path: '/*',
  },
];
const router = createBrowserRouter(routes);

export function AppRoutes() {
  return (
    <Suspense fallback={null}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
