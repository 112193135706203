import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {cover} from 'polished';

import {images} from '../../../assets';
import {MEDIA} from '../../../constants/media';
import {STEPS} from '../constants';
import {useHomeStore} from '../store';
import {Tree} from './Tree';

const Container = styled.div`
  ${cover()}
  background: url(${images.home.linjian}) no-repeat center/cover;
  box-shadow: inset 0px -120px 100px -10px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: 0.6s;

  ${MEDIA.sm} {
    background-position-x: 20%;
    background-size: cover;
  }
`;

export function Linjian() {
  const {step} = useHomeStore();

  return (
    <Container
      css={css`
        ${step.key !== STEPS.check3D.key && {
          opacity: 1,
          visibility: 'visible',
          filter: 'none',
        }}
      `}
    >
      <Tree />
    </Container>
  );
}
