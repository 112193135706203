import {
  Center,
  Flex,
  FormControl,
  FormHelperText,
  Input,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {AnimatePresence, motion} from 'framer-motion';
import {cover, size} from 'polished';
import {useState} from 'react';
import {flushSync} from 'react-dom';
import {RiUser4Line} from 'react-icons/ri';

import {images} from '../../../assets';
import {Image} from '../../../components/Image';
import {MEDIA} from '../../../constants/media';
import {drawNoface} from '../../../utils/drawNoface';
import {selectFiles} from '../../../utils/selectFiles';
import {useGetDigitalData} from '../../auth/api/useGetDigitalData';
import {patchUserInfo} from '../api/patchUserInfo';
import {postNoface} from '../api/postNoface';
import {STEPS} from '../constants';
import {useHomeStore} from '../store';

const ContaienrWrapper = styled(Center)`
  ${cover()}
`;

const Container = styled.div`
  margin-bottom: -100px;
  pointer-events: auto;
  position: relative;
  width: 1000px;
  height: 350px;
  background: rgba(0, 0, 0, 0.4);
  border: 4px solid #ffffff;
  border-radius: 36px;
  backdrop-filter: blur(20px);
  padding: 50px 100px 50px 300px;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  input + * {
    text-align: center;
  }

  ${MEDIA.sm} {
    margin-bottom: 50px;
    font-size: 16px;
    width: 320px;
    height: 300px;
    padding: 30px 30px;
    text-align: center;
  }
`;

const SuccessText = styled.p`
  color: #9ae6b4;
  font-size: 20px;
  margin-bottom: 20px;
`;

const IntroText = styled.p``;

const AvatarBoxWrapper = styled(Container)`
  backdrop-filter: none;
  background: none;
  border: none;
  position: absolute;
  z-index: 1;
  pointer-events: none;
`;

const AvatarBox = styled.div`
  pointer-events: all;
  ${size(200)}
  flex-shrink: 0;
  border: 4px solid #ffffff;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(50px);
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateY(-60%);
  display: flex;
  align-items: center;
  justify-content: center;

  ${MEDIA.sm} {
    ${size(130)}
  }
`;

const NofaceBox = styled.div`
  ${cover()}

  svg {
    width: 100% !important;
    height: 100% !important;

    .fillArea {
      opacity: 0;
    }
  }
`;

const NofacePlaceholder = styled(RiUser4Line)`
  ${size(80)}

  ${MEDIA.sm} {
    ${size(50)}
  }
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-shrink: 0;
  width: 192px;
  height: 48px;
  background: #1d6136;
  opacity: 1;
  border-radius: 32px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;

  &:disabled {
    opacity: 0.4;
  }

  ${MEDIA.sm} {
    width: 120px;
    height: 40px;
  }
`;

const MotionFade = ({children, ...rest}) => (
  <motion.div
    animate={{opacity: 1, scale: 1}}
    exit={{opacity: 0, scale: 1.1}}
    initial={{opacity: 0, scale: 0.8}}
    transition={{type: 'spring', duration: 0.5}}
    {...rest}
  >
    {children}
  </motion.div>
);

export function YournameNoface() {
  const {step, yournameNoface, enterExhibition} = useHomeStore();
  const [username, setUsername] = useState('');
  const [nofaceSrc, setNofaceSrc] = useState('');
  const [nofaceRaw, setNofaceRaw] = useState('');
  const [isSubmitingNoface, setIsSubmitingNoface] = useState(false);
  const [isSubmitingUserInfo, setIsSubmitingUserInfo] = useState(false);
  const toast = useToast();

  const {data} = useGetDigitalData();
  const {contractUrl} = data?.generalData ?? {};

  const isShowYourname = step.key === STEPS.yourname.key;
  const isShowNoface = step.key === STEPS.noface.key;
  const isShowNofaceConfirm = isShowNoface && !!nofaceSrc;
  const isShowNftCard = step.key === STEPS.nftCard.key;
  function handleClickTakePhoto() {
    selectFiles({accept: 'image/*'})
      .then(async ({fileList: [file]}) => {
        setIsSubmitingNoface(true);
        const formData = new FormData();
        formData.append('file', file);
        const {nofacesvg: dataNofaceSrc} = await postNoface(formData).catch(
          (error) => {
            if (error?.response?.data?.message) {
              toast({
                status: 'error',
                title: error.response.data.message,
              });
            }
          }
        );
        setNofaceSrc(dataNofaceSrc);
        const svgRaw = await fetch(dataNofaceSrc).then((res) => res.text());
        flushSync(() => {
          setNofaceRaw(svgRaw);
          yournameNoface.setNofaceRaw(svgRaw);
        });
        drawNoface('nonFacePortraitSvg', () => null, 100);
      })
      .finally(() => setIsSubmitingNoface(false));
  }

  function handleClickCheckNoface() {
    setIsSubmitingUserInfo(true);
    yournameNoface.clickCheckNoface();
    patchUserInfo({username, nofaceimg: nofaceSrc})
      .then(() => {
        yournameNoface.clickCheckNoface();
      })
      .catch((error) => {
        toast({status: 'error', title: error.response.data.message});
      })
      .finally(() => {
        setIsSubmitingUserInfo(false);
      });
  }

  return (
    <ContaienrWrapper>
      {isShowNoface && (
        <AvatarBoxWrapper>
          <AvatarBox>
            {nofaceRaw ? (
              <NofaceBox dangerouslySetInnerHTML={{__html: nofaceRaw}} />
            ) : (
              <NofacePlaceholder />
            )}
            {isSubmitingNoface && (
              <Image
                src={images.loading}
                style={{padding: 30, position: 'relative', zIndex: 1}}
              />
            )}
          </AvatarBox>
        </AvatarBoxWrapper>
      )}

      <Container className='Avatar&Noface'>
        <Spacer />
        <AnimatePresence initial={false} mode='wait'>
          <MotionFade key={step.key}>
            {isShowNftCard && (
              <SuccessText>您已成功拥有创世者身份。</SuccessText>
            )}

            <IntroText>
              {isShowYourname &&
                'Hi，欢迎来到“云图里”，这将是我们共同建设的新家园。我是ina（伊娜），我应该怎么称呼您？'}

              {isShowNoface &&
                (isShowNofaceConfirm ? (
                  <>
                    我们将以此画作为您铸造数字藏品，一经提交将不可更改，它将成为您的私人数字资产
                    {contractUrl && (
                      <a
                        href={contractUrl}
                        rel='noreferrer'
                        style={{
                          color: '#96c6f2',
                          fontSize: 14,
                          fontWeight: 'normal',
                        }}
                        target='_blank'
                      >
                        （查阅智能合约）
                      </a>
                    )}
                    。
                  </>
                ) : (
                  '谢谢您，非常荣幸地通知您：作为云图里首批创建者，您将获得我们为您准备的限量版数字藏品，这也将是您的创世者身份象征。请您拍一张头像'
                ))}

              {isShowNftCard &&
                '准备好了吗？现在我们去看看这个正在建设的新世界，听说岛上第一栋建筑马上就要竣工了。'}
            </IntroText>

            {isShowYourname && (
              <FormControl my={2}>
                <Input
                  // autoFocus
                  _placeholder={{color: 'rgba(255,255,255,.4)'}}
                  autoCorrect={false}
                  fontSize={18}
                  placeholder='您的昵称'
                  textAlign='center'
                  type='text'
                  value={username}
                  variant='flushed'
                  onChange={(ev) => setUsername(ev.target.value)}
                />
                <FormHelperText
                  color='gray.400'
                  fontSize={12}
                  fontWeight='normal'
                  style={{zoom: 0.8}}
                >
                  这是您在云图里数字世界内的昵称，确定后暂不可修改
                </FormHelperText>
              </FormControl>
            )}
          </MotionFade>
        </AnimatePresence>
        <Spacer />

        <motion.div layout>
          <Flex gap={3}>
            {isShowYourname && (
              <Button
                disabled={!username}
                onClick={() => {
                  yournameNoface.clickContinue();
                }}
              >
                继续
              </Button>
            )}

            {isShowNoface &&
              (isShowNofaceConfirm ? (
                <>
                  <Button
                    disabled={isSubmitingNoface}
                    style={{marginTop: 'auto'}}
                    onClick={handleClickTakePhoto}
                  >
                    {isSubmitingNoface && (
                      <Image size={20} src={images.loading2} />
                    )}
                    {isSubmitingNoface ? '玩命生成中' : '重拍'}
                  </Button>
                  <Button
                    disabled={isSubmitingNoface}
                    style={{marginTop: 'auto'}}
                    onClick={handleClickCheckNoface}
                  >
                    {isSubmitingUserInfo && (
                      <Image size={20} src={images.loading2} />
                    )}
                    确定
                  </Button>
                </>
              ) : (
                <Button
                  disabled={isSubmitingNoface}
                  style={{marginTop: 'auto'}}
                  onClick={handleClickTakePhoto}
                >
                  {isSubmitingNoface && (
                    <Image size={20} src={images.loading2} />
                  )}
                  {isSubmitingNoface ? '玩命生成中' : '拍照'}
                </Button>
              ))}

            {isShowNftCard && (
              <Button
                onClick={() => {
                  enterExhibition.enter();
                }}
              >
                去看看
              </Button>
              // <Button replace as={Link} to='/exhibition?isFirst=true'>
              //   去看看
              // </Button>
            )}
          </Flex>
        </motion.div>
      </Container>
    </ContaienrWrapper>
  );
}
