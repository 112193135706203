export const images = {
  logo: require('./images/logo.svg').default,
  footerLogo: require('./images/footerLogo.svg').default,
  loading: require('./images/loading.svg').default,
  loading2: require('./images/loading2.svg').default,
  home: {
    bg: require('./images/home/bg.jpg'),
    bgSmall: require('./images/home/bgSmall.jpg'),
    hand: require('./images/home/hand.png'),
    contractCard: require('./images/home/contractCard.png'),
    contractCardBack: require('./images/home/contractCardBack.png'),
    avatarTexture: require('./images/home/avatarTexture.png'),
    iconSave: require('./images/home/iconSave.png'),
    linjian: require('./images/home/linjian.jpg'),
    video_placer: require('./images/home/截屏2023-03-10 11.38.53.jpg'),
    video_placer_small: require('./images/home/截屏2023-03-10 11.37.10.jpg'),
    grass: require('./images/home/grass.png'),
  },
  exhibition: {
    bg: require('./images/exhibition/2022bg.jpg'),
    holoShadow: require('./images/exhibition/holoShadow.png'),
    logo: require('./images/exhibition/南方日报、南方+logo.png'),
  },
};

export const files = {
  ina: 'https://1.wonderina.com/linjianVideos/ina.glb',
  linjianVideo: 'https://1.wonderina.com/linjianVideos/linjian.mp4',
  linjianMobileVideo: 'https://1.wonderina.com/linjianVideos/linjianMobile.mp4',
};
