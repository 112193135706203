import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {AnimatePresence, motion} from 'framer-motion';
import {cover} from 'polished';
import {useEffect, useState, useRef} from 'react';
import {useCallback} from 'react';
import {useSearchParams} from 'react-router-dom';

import {images} from '../../../assets';
import {Page as DefaultPage} from '../../../components/Page';
import {MEDIA} from '../../../constants/media';
import {useCustomMedia} from '../../../hooks/useCustomMedia';
import {useGetDigitalData} from '../../auth/api/useGetDigitalData';
import {useHomeStore} from '../../home/store';
import {EnterVideo} from '../components/EnterVideo';
import HoloScreen from '../components/HoloScreen';
import {Ina} from '../components/Ina';
import {UserAvatar as UserAvatarDef} from '../components/UserAvatar';
const UserAvatar = motion(UserAvatarDef);

const ExhibitionBg = motion(styled.div`
  ${cover()};
  width: 120%;

  margin-left: -10%;
  background: url(${images.exhibition.bg}) no-repeat center/cover;
  box-shadow: inset 0px -120px 100px -10px rgba(0, 0, 0, 0.3);

  ${MEDIA.sm} {
    background-position-x: center;
    background-size: cover;
    animation: ExhibitionBg_animation 3s ease-in-out 0s;
    animation-fill-mode: both;
  }
  @keyframes ExhibitionBg_animation {
    0% {
      background-size: auto 125%;
    }

    100% {
      background-size: auto 100%;
    }
  }
`);
const Linjian = motion(styled.div`
  ${cover()}
  background: url(${images.exhibition.bg}) no-repeat center/cover;
  box-shadow: inset 0px -120px 100px -10px rgba(0, 0, 0, 0.3);

  transition: 0.6s;

  ${MEDIA.sm} {
    background-position-x: center;
    background-size: cover;
  }
`);
const MotionWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const fadeVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};
const fadeOutVariants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 2,
    },
  },
};
export function ExhibitionPage({autoPlay = true, ...rest}) {
  const {enterExhibition} = useHomeStore();
  const {data, error} = useGetDigitalData();
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const videoRef = useRef();
  const {isSmall} = useCustomMedia();
  const [isOut, setIsOut] = useState(false);
  const [searchParams] = useSearchParams();
  const isEnterExhibition = enterExhibition.show;
  const searchParamsString = searchParams.toString();
  const enter = useCallback(() => {
    window.location.href = `https://x.wonderina.com/${
      searchParamsString ? '?' : ''
    }${searchParamsString}`;
  }, [searchParams]);
  // useEffect(() => {
  //   if (!data && error) {
  //     window.location.href = `https://did.wonderina.com/user-center/login?from=${encodeURI(
  //       window.location.href
  //     )}`;
  //   }
  // }, [data, error]);
  useEffect(() => {
    if (isVideoEnded && searchParams.has('position')) {
      setTimeout(() => {
        setIsOut(true);
      }, 1500);
    }
  }, [isVideoEnded]);

  useEffect(() => {
    if (isOut) {
      setTimeout(() => {
        enter();
      }, 3000);
    }
  }, [isOut]);

  return (
    <DefaultPage className='ExhibitionPage' {...rest} style={{height: '100%'}}>
      <AnimatePresence>
        {!isOut && (
          <motion.div
            exit={{scale: 2, opacity: 0}}
            initial={{scale: 1, opacity: 1}}
            key='EnterVideo'
            style={{
              height: '100%',
              // transformStyle: 'preserve-3d',
              // perspective: 1000,
            }}
            transition={{duration: 3}}
          >
            {!isSmall && (
              <EnterVideo
                autoplay
                ref={videoRef}
                skipLinjian={2}
                onEnded={() => setIsVideoEnded(true)}
              />
            )}
            {isSmall && <ExhibitionBg></ExhibitionBg>}
          </motion.div>
        )}

        {!isOut && isEnterExhibition && (
          <UserAvatar
            css={css`
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%);
              transition: 1s;

              ${isVideoEnded && {
                top: 20,
                right: 20,
                transform: 'none',
              }}
            `}
            exit='out'
            initial='in'
            key='UserAvatar'
            transition={{duration: 1}}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isOut && (
          <MotionWrapper
            animate={isVideoEnded || isSmall ? 'in' : 'out'}
            exit='out'
            initial='out'
            key='HoloScreen'
            transition={{duration: 1}}
            variants={fadeVariants}
            onAnimationComplete={(e) => {
              if (e === 'in') {
                setIsVideoEnded(true);
              }
            }}
          >
            <HoloScreen />
            <Ina />
          </MotionWrapper>
        )}
      </AnimatePresence>
      {/* <AnimatePresence>
        {!isOut && !isEnterExhibition && isSmall && (
          <Linjian
            animate='show'
            exit='hide'
            key='YournameNoface'
            variants={fadeOutVariants}
          >
            <YournameNoface />
          </Linjian>
        )}
      </AnimatePresence> */}
    </DefaultPage>
  );
}
