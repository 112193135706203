import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {motion} from 'framer-motion';
import {AnimatePresence} from 'framer-motion';
import {useRef, useState} from 'react';
import {Route, Routes} from 'react-router-dom';

import {EnterVideo} from '../../exhibition/components/EnterVideo';
import HoloScreen from '../../exhibition/components/HoloScreen';
import {Ina} from '../../exhibition/components/Ina';
import {UserAvatar} from '../../exhibition/components/UserAvatar';
import {useHomeStore} from '../store';
import {HomePage} from './HomePage';

const MotionWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export function HomeRoutes() {
  const {enterExhibition} = useHomeStore();
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const videoRef = useRef();

  const isEnterExhibition = enterExhibition.show;
  const isSkip = enterExhibition.skip;

  return (
    <Routes>
      <Route
        index
        element={
          <AnimatePresence>
            {isEnterExhibition ? (
              <MotionWrapper
                animate={{opacity: 1}}
                initial={{opacity: 0}}
                key='exhibition'
                transition={{type: 'tween', delay: 1}}
              >
                <EnterVideo
                  ref={videoRef}
                  skipLinjian={isSkip}
                  onEnded={() => setIsVideoEnded(true)}
                />

                <UserAvatar
                  css={css`
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    transform: translate(50%, -50%);
                    transition: 1s;

                    ${isVideoEnded && {
                      top: 20,
                      right: 20,
                      transform: 'none',
                    }}
                  `}
                />
                {isEnterExhibition && (
                  <MotionWrapper
                    animate={{opacity: isVideoEnded ? 1 : 0}}
                    initial={{opacity: 0}}
                  >
                    <HoloScreen />
                    <Ina />
                  </MotionWrapper>
                )}
              </MotionWrapper>
            ) : (
              <MotionWrapper
                exit={{opacity: 0}}
                key='home'
                transition={{type: 'tween', duration: 1}}
              >
                <HomePage />
              </MotionWrapper>
            )}
          </AnimatePresence>
        }
      />
    </Routes>
  );
}
