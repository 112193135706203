import {ChakraProvider} from '@chakra-ui/react';
import {ErrorBoundary} from 'react-error-boundary';
import {HelmetProvider} from 'react-helmet-async';
import {SWRConfig} from 'swr';

import {ErrorFallback} from './components/ErrorFallback';
import {StandaloneToastContainer} from './components/StandaloneToast';
import {GlobalStyles} from './GlobalStyles';
import {request} from './lib/request';

const __DevClickToComponent =
  process.env.NODE_ENV === 'development'
    ? require('click-to-react-component').ClickToComponent
    : () => null;

export function AppProviders({children}) {
  return (
    <ErrorBoundary fallbackRender={ErrorFallback}>
      <HelmetProvider>
        <SWRConfig value={{fetcher: request.get}}>
          <ChakraProvider>
            {children}
            <GlobalStyles />
            {/* <DevClickToComponent /> */}
            <StandaloneToastContainer />
          </ChakraProvider>
        </SWRConfig>
      </HelmetProvider>
    </ErrorBoundary>
  );
}
