export const STEPS = {
  tutorial: {
    key: 'tutorial',
    stepNum: 1,
    stepCaption: '欢迎, 您即将进入“云图里”',
    indicateTitle: '三笔画一个分叉',
  },
  draw2D: {
    key: 'draw2D',
    stepNum: 1,
    stepCaption: '欢迎, 您即将进入“云图里”',
    indicateTitle: '三笔画一个分叉',
  },
  check2D: {
    key: 'check2D',
    stepNum: 2,
    stepCaption: '您正在创造一个生命体',
    indicateTitle: '长成一棵树',
  },
  check3D: {
    key: 'check3D',
    stepNum: 3,
    stepCaption: '把树种进云图里！',
    indicateTitle: '恭喜您，这是独一无二的',
  },
  linjian: {
    key: 'linjian',
    stepNum: 3,
    stepCaption: '把树种进云图里！',
    indicateTitle: '恭喜您，这是独一无二的',
  },
  yourname: {
    key: 'yourname',
    stepNum: 4,
    stepCaption: '定义您的昵称',
    indicateTitle: null,
  },
  noface: {
    key: 'noface',
    stepNum: 5,
    stepCaption: '生成您的无脸画',
    indicateTitle: null,
  },
  nftCard: {
    key: 'nftCard',
    stepNum: 5,
    stepCaption: '完成!',
    indicateTitle: null,
  },
};
