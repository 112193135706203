import {produce} from 'immer';
import create from 'zustand';

import {STEPS} from './constants';

const searchParams = new URLSearchParams(location.search);
const stepQuery = searchParams.get('step');
const isRedraw = searchParams.get('isRedraw') === 'true';

export const useHomeStore = create((set) => ({
  step: stepQuery ? STEPS[stepQuery] : isRedraw ? STEPS.draw2D : STEPS.tutorial,
  tutorial: {
    key: 0, // add key to refresh play
    hasEnd: false, // 播放已结束
    endPlay: () =>
      set(
        produce((state) => {
          state.tutorial.hasEnd = true;
        })
      ),
    clickReplay: () =>
      set(
        produce((state) => {
          state.tutorial.key++;
          state.tutorial.hasEnd = false;
        })
      ),
    clickFinish: () =>
      set(
        produce((state) => {
          state.step = STEPS.draw2D;
        })
      ),
  },
  drawTree: {
    material: {},
    setMaterial: (data) =>
      set(
        produce((state) => {
          state.drawTree.material = data;
        })
      ),
    clickCheckDraw: () =>
      set(
        produce((state) => {
          state.step = STEPS.check2D;
        })
      ),
    clickThatsIt: () =>
      set(
        produce((state) => {
          state.step = STEPS.check3D;
        })
      ),
    clickEnterNewWorld: () =>
      set(
        produce((state) => {
          state.step = STEPS.linjian;
        })
      ),
    clickContinue: () =>
      set(
        produce((state) => {
          state.step = STEPS.yourname;
        })
      ),
  },
  yournameNoface: {
    nofaceRaw: null,
    // nofaceRaw: `<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="1440.000000pt" height="1440.000000pt" viewBox="0 0 1440.000000 1440.000000" preserveAspectRatio="xMidYMid meet" style="background-color:#E0CADC" id="nonFacePortraitSvg"><path data-ignore="true" fill="#E0CADC" class="svg_color_bg" d="M0 0 L1440 0 L1440 1440 L0 1440 Z"></path><g transform="translate(21.166370084252783,1460.7584866706522) scale(0.252632,-0.252632)" fill="#fdceb2" class="svg_color_0_0 fillArea" stroke="none" style="transition: all 0.5s ease 0s; opacity: 1;">
    // <path data-ignore="true" d="M1896 4783 c5 -27 8 -120 9 -208 l0 -161 -31 -39 c-46 -59 -78 -130 -101 -225 -12 -47 -28 -115 -37 -151 -9 -36 -24 -86 -35 -109 -13 -30 -17 -50 -11 -66 5 -12 7 -24 5 -26 -3 -2 -9 -55 -15 -118 -8 -91 -7 -122 5 -159 8 -25 19 -64 24 -86 6 -22 29 -62 51 -89 35 -42 40 -54 40 -100 0 -28 5 -69 10 -91 5 -22 12 -87 16 -145 8 -144 29 -215 76 -266 35 -38 39 -49 48 -123 17 -134 56 -269 109 -376 46 -94 50 -108 62 -223 8 -81 10 -129 3 -139 -17 -26 -127 -60 -221 -66 -49 -4 -140 -17 -201 -31 -91 -19 -138 -24 -245 -23 -126 2 -135 3 -204 34 -114 52 -143 48 -380 -48 l-83 -34 0 -442 0 -443 2000 0 2000 0 0 474 0 473 -97 7 c-114 8 -183 23 -311 66 -51 17 -123 33 -160 36 -162 13 -254 34 -332 76 l-74 41 -112 -6 c-84 -5 -114 -3 -117 6 -2 7 -2 64 1 127 5 106 8 120 42 195 36 77 44 110 70 300 10 72 14 83 45 111 58 52 79 130 96 347 11 131 16 160 31 174 16 14 22 15 40 4 34 -21 43 1 22 56 -14 38 -18 77 -19 183 0 185 13 424 28 488 10 44 15 52 35 52 20 0 22 4 17 23 -4 12 -15 31 -25 42 -10 11 -21 41 -24 66 -6 38 -3 48 14 64 24 21 28 89 7 114 -17 20 -67 155 -67 181 0 10 -32 48 -72 85 -82 74 -81 90 5 81 30 -3 47 -1 47 6 0 5 -23 37 -51 69 l-51 59 -945 0 -945 0 8 -47z"></path>
    // </g><g transform="translate(21.166370084252783,1460.7584866706522) scale(0.281250,-0.281250)" fill="#5b6e78" class="svg_color_0 fillArea" stroke="none" style="transition: all 0.5s ease 0s; opacity: 1;">
    // <path data-ignore="true" d="M3218 1795 c-9 -19 -6 -63 11 -198 11 -95 26 -188 31 -207 6 -19 10 -65 10 -101 0 -95 -30 -158 -111 -232 -35 -31 -75 -67 -88 -80 -13 -12 -40 -35 -61 -52 -21 -16 -70 -56 -108 -87 -76 -62 -103 -72 -232 -84 -111 -10 -175 7 -232 59 -25 23 -53 56 -62 73 -8 17 -22 34 -30 37 -8 3 -26 28 -40 54 -14 26 -55 83 -93 125 -37 42 -88 102 -113 133 -25 31 -59 73 -76 93 -17 20 -39 53 -49 72 -10 19 -33 57 -50 85 -18 27 -43 79 -55 115 l-23 65 -91 -8 c-50 -5 -131 -15 -181 -23 -260 -44 -351 -44 -455 1 -57 24 -74 27 -138 23 -63 -4 -87 -12 -170 -54 -112 -58 -135 -87 -151 -195 -5 -35 -23 -93 -39 -129 -17 -36 -37 -82 -46 -102 -8 -21 -30 -47 -50 -60 -42 -27 -87 -124 -106 -228 -23 -124 -53 -222 -88 -278 -18 -31 -44 -77 -57 -102 -13 -25 -40 -61 -60 -80 -20 -19 -57 -64 -83 -100 -25 -36 -65 -82 -89 -103 l-43 -39 0 -94 0 -94 2379 0 2379 0 4 42 c3 34 -3 56 -29 106 -18 35 -33 70 -33 78 0 8 -38 71 -84 139 -83 122 -84 125 -116 257 -18 73 -36 141 -41 150 -5 9 -24 22 -43 28 -31 10 -36 16 -40 53 -19 144 -26 245 -31 427 -4 113 -8 215 -11 227 -10 50 -80 91 -199 117 -44 9 -145 30 -225 47 -302 64 -364 79 -419 100 -45 16 -212 49 -253 49 -5 0 -14 -11 -20 -25z"></path>
    // </g><g transform="translate(21.166370084252783,1460.7584866706522) scale(0.281250,-0.281250)" fill="#401d09" class="svg_color_1 fillArea" stroke="none" style="transition: all 0.5s ease 0s; opacity: 1;">
    // <path data-ignore="true" d="M2625 4629 c-151 -15 -242 -43 -329 -103 -22 -14 -61 -34 -87 -43 -38 -14 -49 -23 -51 -42 -2 -19 -13 -27 -58 -42 -30 -10 -71 -18 -90 -18 -19 -1 -79 -6 -133 -13 -76 -9 -104 -17 -122 -34 -27 -25 -45 -99 -50 -207 -5 -106 -12 -132 -55 -207 -38 -66 -73 -163 -86 -235 -4 -22 -13 -58 -21 -80 -7 -21 -22 -94 -33 -161 -19 -115 -19 -128 -5 -210 24 -133 29 -151 81 -264 44 -97 48 -112 49 -190 2 -118 20 -185 60 -229 l33 -35 7 58 c4 32 5 110 3 172 -3 63 -1 133 5 156 5 24 13 133 16 243 6 190 7 202 32 251 15 28 34 82 43 120 30 120 99 307 134 364 39 62 122 146 155 156 12 4 33 14 47 24 33 23 172 50 290 57 141 8 373 -21 410 -52 8 -7 38 -24 66 -38 100 -50 227 -223 274 -372 15 -48 59 -153 106 -253 31 -65 34 -76 24 -110 -10 -39 1 -328 16 -399 l6 -32 37 29 c20 16 55 32 78 36 57 9 60 22 62 281 1 116 6 244 11 285 5 40 10 160 10 267 0 240 -16 322 -89 451 -60 106 -97 137 -187 157 -80 18 -91 24 -99 63 -10 44 -133 154 -203 181 -67 25 -212 33 -357 18z"></path>
    // </g><g transform="translate(21.166370084252783,1460.7584866706522) scale(0.281250,-0.281250)" fill="#fdceb2" class="svg_color_2 fillArea" stroke="none" style="transition: all 0.5s ease 0s; opacity: 1;">
    // <path data-ignore="true" d="M2390 4113 c-115 -18 -199 -39 -220 -53 -14 -10 -35 -20 -47 -24 -33 -10 -116 -94 -155 -156 -35 -57 -104 -244 -134 -364 -9 -38 -28 -92 -43 -120 -25 -49 -26 -61 -32 -251 -3 -110 -11 -219 -16 -243 -6 -23 -8 -93 -5 -156 2 -62 1 -140 -3 -172 l-7 -58 -33 35 c-40 44 -58 111 -60 229 -1 56 -8 97 -19 120 -13 26 -16 29 -12 10 3 -14 8 -65 11 -115 11 -155 41 -259 92 -316 8 -9 22 -57 33 -107 21 -103 76 -281 113 -362 29 -65 52 -204 42 -257 -3 -21 -16 -48 -28 -61 l-21 -23 23 -67 c13 -37 38 -90 56 -117 17 -28 40 -66 50 -85 10 -19 32 -52 49 -72 17 -20 51 -62 76 -93 25 -31 76 -91 113 -133 38 -42 79 -99 93 -125 14 -26 32 -51 40 -54 8 -3 22 -20 30 -37 9 -17 37 -50 62 -73 57 -52 121 -69 232 -59 129 12 156 22 232 84 38 31 87 71 108 87 21 17 48 40 61 52 13 13 53 49 88 80 81 74 111 137 111 232 0 36 -4 81 -10 100 -5 19 -19 110 -31 204 -17 134 -19 177 -10 206 6 20 10 47 10 61 -3 57 15 178 32 219 11 25 34 115 51 200 26 131 36 161 60 189 15 18 34 45 41 60 14 27 60 210 72 287 4 21 13 52 21 67 23 45 19 51 -28 44 -24 -4 -59 -20 -79 -36 l-37 -29 -6 32 c-15 71 -26 360 -16 399 10 34 7 45 -24 110 -47 100 -91 205 -106 253 -47 149 -174 322 -274 372 -28 14 -58 31 -66 38 -43 36 -357 68 -480 48z"></path>
    // </g><g transform="translate(21.166370084252783,1460.7584866706522) scale(0.281250,-0.281250)" fill="#fdceb2" class="svg_color_3 fillArea" stroke="none" style="transition: all 0.5s ease 0s; opacity: 1;">
    // <path data-ignore="true" d="M2390 4113 c-115 -18 -199 -39 -220 -53 -14 -10 -35 -20 -47 -24 -33 -10 -116 -94 -155 -156 -35 -57 -104 -244 -134 -364 -9 -38 -28 -92 -43 -120 -25 -49 -26 -61 -32 -251 -3 -110 -11 -219 -16 -243 -6 -23 -8 -93 -5 -156 2 -62 1 -140 -3 -172 l-7 -58 -33 35 c-40 44 -58 111 -60 229 -1 56 -8 97 -19 120 -13 26 -16 29 -12 10 3 -14 8 -65 11 -115 11 -155 41 -259 92 -316 8 -9 22 -57 33 -107 21 -106 77 -283 113 -362 31 -66 132 -171 211 -219 99 -59 217 -100 325 -113 169 -20 411 25 586 108 84 39 248 210 288 298 9 21 32 109 49 195 26 131 36 161 60 189 15 18 34 45 41 60 14 27 60 210 72 287 4 21 13 52 21 67 23 45 19 51 -28 44 -24 -4 -59 -20 -79 -36 l-37 -29 -6 32 c-15 71 -26 360 -16 399 10 34 7 45 -24 110 -47 100 -91 205 -106 253 -47 149 -174 322 -274 372 -28 14 -58 31 -66 38 -43 36 -357 68 -480 48z"></path>
    // </g><g transform="translate(21.166370084252783,1460.7584866706522) scale(0.281250,-0.281250)" fill="#333333" class="svg_color_4 fillArea" stroke="none" style="transition: all 0.5s ease 0s; opacity: 1;">
    // </g><g transform="translate(21.166370084252783,1460.7584866706522) scale(2.250000,-2.250000)" fill="#031a27" class="cloth_cluster fillArea" stroke="none" style="transition: all 0.5s ease 0s; opacity: 1;">
    // <path data-ignore="true" d="M416 212 c26 -17 21 -104 -5 -88 -4 3 -16 0 -25 -7 -15 -11 -5 -13 58 -16 l76 -2 0 40 c0 23 0 44 1 48 0 4 -21 13 -48 20 -55 14 -73 16 -57 5z m51 -18 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m46 -11 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"></path>
    // <path data-ignore="true" d="M130 190 c34 -11 60 -11 35 0 -11 5 -29 8 -40 8 -16 0 -15 -2 5 -8z"></path>
    // <path data-ignore="true" d="M198 193 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"></path>
    // <path data-ignore="true" d="M411 170 c-1 -23 2 -31 9 -24 7 7 7 19 1 35 -9 23 -10 22 -10 -11z"></path>
    // <path data-ignore="true" d="M114 168 c-3 -4 -8 -6 -12 -4 -4 2 -8 -11 -9 -30 l-3 -34 90 0 c57 0 90 4 90 11 0 5 6 7 13 3 6 -4 3 2 -7 14 -23 26 -34 29 -15 5 11 -15 10 -14 -9 1 -23 20 -31 42 -9 30 7 -4 9 -3 4 2 -11 12 -126 14 -133 2z"></path>
    // </g><g transform="translate(21.166370084252783,1460.7584866706522) scale(2.250000,-2.250000)" fill="#fefefe" class="cloth_cluster fillArea" stroke="none" style="transition: all 0.5s ease 0s; opacity: 1;">
    // <path data-ignore="true" d="M64 128 c-4 -7 -3 -8 4 -4 34 21 -1 -67 -39 -99 l-24 -20 20 25 20 25 -22 -19 c-13 -10 -20 -23 -17 -27 3 -5 131 -9 285 -9 l280 0 -6 21 c-5 16 -4 19 5 14 9 -5 11 -4 6 4 -4 6 -12 9 -17 6 -5 -4 -13 5 -19 19 -9 25 -13 26 -89 26 -44 0 -83 -4 -86 -10 -8 -13 -62 -13 -70 0 -3 6 -49 10 -101 10 -92 0 -95 1 -104 25 -11 28 -15 30 -26 13z"></path>
    // <path data-ignore="true" d="M56 93 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"></path>
    // <path data-ignore="true" d="M540 95 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25 -5 8 -10 11 -10 5z"></path>
    // <path data-ignore="true" d="M313 83 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"></path>
    // </g><g transform="translate(21.166370084252783,1460.7584866706522) scale(2.250000,-2.250000)" fill="#5b6e78" class="cloth_cluster fillArea" stroke="none" style="transition: all 0.5s ease 0s; opacity: 1;">
    // <path data-ignore="true" d="M531 138 l-1 -37 -72 -4 -73 -3 73 -2 c79 -2 87 3 79 55 -3 24 -4 22 -6 -9z"></path>
    // <path data-ignore="true" d="M142 93 c27 -2 69 -2 96 0 26 2 4 3 -48 3 -52 0 -74 -1 -48 -3z"></path>
    // </g><g transform="translate(0.000000,1440.000000) scale(0.281250,-0.281250)" fill="none" stroke="#000000" stroke-width="120">
    // <path d="M2625 4629 c-151 -15 -242 -43 -329 -103 -22 -14 -61 -34 -87 -43 -38 -14 -49 -23 -51 -42 -2 -19 -13 -27 -58 -42 -30 -10 -71 -18 -90 -18 -19 -1 -79 -6 -133 -13 -76 -9 -104 -17 -122 -34 -27 -25 -45 -99 -50 -207 -5 -106 -12 -132 -55 -207 -38 -66 -73 -163 -86 -235 -4 -22 -13 -58 -21 -80 -7 -21 -22 -94 -33 -161 -19 -115 -19 -128 -5 -210 24 -133 29 -151 81 -264 44 -97 48 -112 49 -190 2 -118 20 -185 60 -229 l33 -35 7 58 c4 32 5 110 3 172 -3 63 -1 133 5 156 5 24 13 133 16 243 6 190 7 202 32 251 15 28 34 82 43 120 30 120 99 307 134 364 39 62 122 146 155 156 12 4 33 14 47 24 33 23 172 50 290 57 141 8 373 -21 410 -52 8 -7 38 -24 66 -38 100 -50 227 -223 274 -372 15 -48 59 -153 106 -253 31 -65 34 -76 24 -110 -10 -39 1 -328 16 -399 l6 -32 37 29 c20 16 55 32 78 36 57 9 60 22 62 281 1 116 6 244 11 285 5 40 10 160 10 267 0 240 -16 322 -89 451 -60 106 -97 137 -187 157 -80 18 -91 24 -99 63 -10 44 -133 154 -203 181 -67 25 -212 33 -357 18z" style="stroke-dasharray: 9101, 9103; stroke-dashoffset: 0;"></path>
    // </g><g transform="translate(0.000000,1440.000000) scale(0.281250,-0.281250)" fill="none" stroke="#000000" stroke-width="120">
    // <path d="M2390 4113 c-115 -18 -199 -39 -220 -53 -14 -10 -35 -20 -47 -24 -33 -10 -116 -94 -155 -156 -35 -57 -104 -244 -134 -364 -9 -38 -28 -92 -43 -120 -25 -49 -26 -61 -32 -251 -3 -110 -11 -219 -16 -243 -6 -23 -8 -93 -5 -156 2 -62 1 -140 -3 -172 l-7 -58 -33 35 c-40 44 -58 111 -60 229 -1 56 -8 97 -19 120 -13 26 -16 29 -12 10 3 -14 8 -65 11 -115 11 -155 41 -259 92 -316 8 -9 22 -57 33 -107 21 -103 76 -281 113 -362 29 -65 52 -204 42 -257 -3 -21 -16 -48 -28 -61 l-21 -23 23 -67 c13 -37 38 -90 56 -117 17 -28 40 -66 50 -85 10 -19 32 -52 49 -72 17 -20 51 -62 76 -93 25 -31 76 -91 113 -133 38 -42 79 -99 93 -125 14 -26 32 -51 40 -54 8 -3 22 -20 30 -37 9 -17 37 -50 62 -73 57 -52 121 -69 232 -59 129 12 156 22 232 84 38 31 87 71 108 87 21 17 48 40 61 52 13 13 53 49 88 80 81 74 111 137 111 232 0 36 -4 81 -10 100 -5 19 -19 110 -31 204 -17 134 -19 177 -10 206 6 20 10 47 10 61 -3 57 15 178 32 219 11 25 34 115 51 200 26 131 36 161 60 189 15 18 34 45 41 60 14 27 60 210 72 287 4 21 13 52 21 67 23 45 19 51 -28 44 -24 -4 -59 -20 -79 -36 l-37 -29 -6 32 c-15 71 -26 360 -16 399 10 34 7 45 -24 110 -47 100 -91 205 -106 253 -47 149 -174 322 -274 372 -28 14 -58 31 -66 38 -43 36 -357 68 -480 48z" style="stroke-dasharray: 9537, 9539; stroke-dashoffset: 0;"></path>
    // </g><g transform="translate(0.000000,1440.000000) scale(0.281250,-0.281250)" fill="none" stroke="#000000" stroke-width="120">
    // <path d="M2390 4113 c-115 -18 -199 -39 -220 -53 -14 -10 -35 -20 -47 -24 -33 -10 -116 -94 -155 -156 -35 -57 -104 -244 -134 -364 -9 -38 -28 -92 -43 -120 -25 -49 -26 -61 -32 -251 -3 -110 -11 -219 -16 -243 -6 -23 -8 -93 -5 -156 2 -62 1 -140 -3 -172 l-7 -58 -33 35 c-40 44 -58 111 -60 229 -1 56 -8 97 -19 120 -13 26 -16 29 -12 10 3 -14 8 -65 11 -115 11 -155 41 -259 92 -316 8 -9 22 -57 33 -107 21 -106 77 -283 113 -362 31 -66 132 -171 211 -219 99 -59 217 -100 325 -113 169 -20 411 25 586 108 84 39 248 210 288 298 9 21 32 109 49 195 26 131 36 161 60 189 15 18 34 45 41 60 14 27 60 210 72 287 4 21 13 52 21 67 23 45 19 51 -28 44 -24 -4 -59 -20 -79 -36 l-37 -29 -6 32 c-15 71 -26 360 -16 399 10 34 7 45 -24 110 -47 100 -91 205 -106 253 -47 149 -174 322 -274 372 -28 14 -58 31 -66 38 -43 36 -357 68 -480 48z" style="stroke-dasharray: 7892, 7894; stroke-dashoffset: 0;"></path>
    // </g><g transform="translate(0.000000,1440.000000) scale(0.281250,-0.281250)" fill="none" stroke="#000000" stroke-width="120">
    // </g><g transform="translate(0.000000,1440.000000) scale(0.281250,-0.281250)" fill="none" stroke="#000000" stroke-width="120">
    // <path d="M3218 1795 c-9 -19 -6 -63 11 -198 11 -95 26 -188 31 -207 6 -19 10 -65 10 -101 0 -95 -30 -158 -111 -232 -35 -31 -75 -67 -88 -80 -13 -12 -40 -35 -61 -52 -21 -16 -70 -56 -108 -87 -76 -62 -103 -72 -232 -84 -111 -10 -175 7 -232 59 -25 23 -53 56 -62 73 -8 17 -22 34 -30 37 -8 3 -26 28 -40 54 -14 26 -55 83 -93 125 -37 42 -88 102 -113 133 -25 31 -59 73 -76 93 -17 20 -39 53 -49 72 -10 19 -33 57 -50 85 -18 27 -43 79 -55 115 l-23 65 -91 -8 c-50 -5 -131 -15 -181 -23 -260 -44 -351 -44 -455 1 -57 24 -74 27 -138 23 -63 -4 -87 -12 -170 -54 -112 -58 -135 -87 -151 -195 -5 -35 -23 -93 -39 -129 -17 -36 -37 -82 -46 -102 -8 -21 -30 -47 -50 -60 -42 -27 -87 -124 -106 -228 -23 -124 -53 -222 -88 -278 -18 -31 -44 -77 -57 -102 -13 -25 -40 -61 -60 -80 -20 -19 -57 -64 -83 -100 -25 -36 -65 -82 -89 -103 l-43 -39 0 -94 0 -94 2379 0 2379 0 4 42 c3 34 -3 56 -29 106 -18 35 -33 70 -33 78 0 8 -38 71 -84 139 -83 122 -84 125 -116 257 -18 73 -36 141 -41 150 -5 9 -24 22 -43 28 -31 10 -36 16 -40 53 -19 144 -26 245 -31 427 -4 113 -8 215 -11 227 -10 50 -80 91 -199 117 -44 9 -145 30 -225 47 -302 64 -364 79 -419 100 -45 16 -212 49 -253 49 -5 0 -14 -11 -20 -25z" style="stroke-dasharray: 13160, 13162; stroke-dashoffset: 0;"></path>
    // </g></svg>`,
    clickContinue: () =>
      set(
        produce((state) => {
          state.step = STEPS.noface;
        })
      ),
    clickCheckNoface: () =>
      set(
        produce((state) => {
          state.step = STEPS.nftCard;
        })
      ),
    setNofaceRaw: (str) =>
      set(
        produce((state) => {
          state.yournameNoface.nofaceRaw = str;
        })
      ),
  },
  enterExhibition: {
    show: false,
    skip: false,
    enter: () =>
      set(
        produce((state) => {
          state.enterExhibition.show = true;
          state.enterExhibition.skip = false;
        })
      ),
    enterSkip: () =>
      set(
        produce((state) => {
          state.enterExhibition.show = true;
          state.enterExhibition.skip = true;
        })
      ),
  },
}));
