import {useSearchParams} from 'react-router-dom';
export function useGetPrivateParams() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const sid = searchParams.get('sid');
  const invitecode = searchParams.get('invitecode');

  return {
    id,
    sid,
    invitecode,
  };
}
