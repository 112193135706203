import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useEffect, useRef, useState} from 'react';

import {images} from '../../../assets';
import {Image} from '../../../components/Image';
import {useHomeStore} from '../store';

const Container = styled.div`
  flex: 1;
  position: relative;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  transition: 0.5s;
  margin-top: 80px;
`;

function TrackedHand({circleRef}) {
  const [offset, setOffset] = useState({});
  const {tutorial} = useHomeStore();

  useEffect(() => {
    const dom = circleRef.current;
    let id;
    const animate = () => {
      id = requestAnimationFrame(() => {
        setOffset({
          x: dom.cx.animVal.value,
          y: dom.cy.animVal.value,
        });

        animate();
      });
    };
    animate();
    return () => cancelAnimationFrame(id);
  }, [circleRef, tutorial.key]);

  if (!offset.x || !offset.y) return null;

  return (
    <Image
      src={images.home.hand}
      style={{
        position: 'absolute',
        left: offset.x,
        top: offset.y,
        margin: 'auto',
        objectPosition: '-60px 0px',
      }}
      width={295}
    />
  );
}

export function TutorialAnimation({onAnimationEnd}) {
  const aniBeginRef = useRef();
  const aniEndRef = useRef();
  const circleRef = useRef();

  const {tutorial} = useHomeStore();

  useEffect(() => {
    function handleEnd() {
      onAnimationEnd?.();
      tutorial.endPlay();
    }
    const endDom = aniEndRef.current;
    endDom?.addEventListener('endEvent', handleEnd);

    return () => {
      endDom?.removeEventListener('endEvent', handleEnd);
    };
  }, [onAnimationEnd, tutorial]);

  return (
    <Container className='TutorialAnimation'>
      <svg
        css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        `}
        key={tutorial.key}
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <line
          stroke='#1E6136'
          strokeLinecap='round'
          strokeWidth={0}
          x1='50%'
          x2='50%'
          y1='40%'
          y2='40%'
        >
          <set attributeName='stroke-width' begin='o0.end' to={12} />
          <animate
            attributeName='x1'
            begin='o0.end'
            dur={1}
            fill='freeze'
            id='o1'
            values='50%;30%'
          />
          <animate
            attributeName='y1'
            begin='o0.end'
            dur={1}
            fill='freeze'
            values='40%;20%'
          />
        </line>

        <line
          stroke='#1E6136'
          strokeLinecap='round'
          strokeWidth={0}
          x1='50%'
          x2='50%'
          y1='40%'
          y2='40%'
        >
          <set attributeName='stroke-width' begin='o1.end' to={12} />
          <animate
            attributeName='x1'
            begin='o1.end'
            dur={1}
            fill='freeze'
            id='o2'
            values='50%;80%'
          />
          <animate
            attributeName='y1'
            begin='o1.end'
            dur={1}
            fill='freeze'
            values='40%;15%'
          />
        </line>
        <line
          stroke='#1E6136'
          strokeLinecap='round'
          strokeWidth={12}
          x1='50%'
          x2='50%'
          y1='60%'
          y2='60%'
        >
          <animate
            attributeName='y1'
            dur={1}
            fill='freeze'
            id='o0'
            ref={aniBeginRef}
            values='60%;40%'
          />
        </line>

        <circle cx='50%' cy='60%' fill='#00CA86' opacity={0.7} r={6}>
          <animate
            attributeName='cx'
            begin='c0.end'
            dur={1}
            id='c1'
            values='50%;30%'
          />
          <animate attributeName='cy' begin='c0.end' dur={1} values='40%;20%' />
          <animate
            attributeName='cx'
            begin='c1.end'
            dur={1}
            id='c2'
            values='50%;80%'
          />
          <animate attributeName='cy' begin='c1.end' dur={1} values='40%;15%' />
          <animate attributeName='cy' dur={1} id='c0' values='60%;40%' />
        </circle>
        <circle
          cx='50%'
          cy='60%'
          fill='none'
          opacity={0.7}
          r={12}
          ref={circleRef}
          stroke='#F1FFBE'
          strokeWidth={4}
        >
          <animate attributeName='cx' begin='c0.end' dur={1} values='50%;30%' />
          <animate attributeName='cy' begin='c0.end' dur={1} values='40%;20%' />
          <animate
            attributeName='cx'
            begin='c1.end'
            dur={1}
            id='c2'
            values='50%;80%'
          />
          <animate
            attributeName='cy'
            begin='c1.end'
            dur={1}
            ref={aniEndRef}
            values='40%;15%'
          />
          <animate attributeName='cy' dur={1} values='60%;40%' />
        </circle>
      </svg>
      <TrackedHand circleRef={circleRef} />
    </Container>
  );
}
