// https://wiki.anyweb.cc/docs/SDK/usage

import {Provider} from '@idealight-labs/anyweb-js-sdk';

import {standaloneToast} from '../../components/StandaloneToast';
import {storage} from '../../utils/storage';
import {addAnywebTitle} from './addAnywebTitle';
import {requestLogin} from './api/requestLogin';
import {requestLogout} from './api/requestLogout';

const appId = '5c7295d9-74a8-4c40-8db6-20b3de35586f';

export let provider = null;

function anywebInit() {
  return new Promise((resolve) => {
    if (provider === null) {
      provider = new Provider({
        logger: null,
        appId,
      });
      provider.on('ready', () => {
        addAnywebTitle();
        resolve();
      });
    } else {
      resolve();
    }
  });
}

export const anyweb = {
  provider,
  async login() {
    await anywebInit();
    try {
      // eslint-disable-next-line no-unused-vars
      const {address, code, chainId, networkId} = await provider.request({
        method: 'cfx_accounts',
        params: [
          {
            availableNetwork: [1029],
            scopes: ['baseInfo', 'identity'],
          },
        ],
      });
      // eslint-disable-next-line no-console
      // console.log('DApp 获取到的授权结果', chainId, networkId, address, code);

      const data = await requestLogin({
        code,
        address: address[0],
        action: 'login',
      });

      if (!data) throw new Error('登录失败');

      const token = data.token;
      storage.setToken(token);
      standaloneToast({status: 'success', title: '已登录'});

      return token;
    } catch (err) {
      standaloneToast({status: 'error', title: '登录失败，请重试'});
      return null;
    }
  },
  async logout() {
    await anywebInit();
    try {
      const res = await provider.request({method: 'anyweb_logout'});
      await requestLogout();
      storage.removeToken();
      standaloneToast({status: 'success', title: '已退出登录'});
      return res;
    } catch {
      standaloneToast({status: 'error', title: '退出登录失败，请重试'});
      return null;
    }
  },
};
