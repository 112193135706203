export function addAnywebTitle() {
  const iframeMask = document.getElementById('anyweb-iframe-mask');

  if (document.getElementById('anyweb-custom-title')) return;

  const customTitle = document.createElement('h2');
  customTitle.id = 'anyweb-custom-title';
  customTitle.innerHTML = '您正在<span>创建/登录</span>基于区块链的账户身份';
  iframeMask.appendChild(customTitle);

  const customFooter = document.createElement('p');
  customFooter.id = 'anyweb-custom-footer';
  customFooter.innerHTML = `该账户基于国内公链<a href="https://www.tree-graph.org.cn/" target="_blank">Conflux</a>，由<a href="https://www.anyweb.cc/" target="_blank">Anyweb</a>提供登录及认证服务`;
  iframeMask.appendChild(customFooter);
}
