const input = document.createElement('input');

export function selectFiles({multiple = false, accept} = {}) {
  Object.assign(input, {
    type: 'file',
    multiple,
    accept,
    value: '',
  });

  return new Promise((res) => {
    input.onchange = () => {
      const {files} = input;
      const fileList = Array.from(input.files);

      res({files, fileList});
    };
    input.click();
  });
}
