import {Center} from '@chakra-ui/react';
import styled from '@emotion/styled';

import {MEDIA} from '../../../constants/media';
import {useHomeStore} from '../store';

const Container = styled(Center)`
  position: absolute;
  top: 140px;
  width: 100%;
`;

const TitleText = styled.p`
  color: white;
  font-size: 36px;
  font-weight: bold;

  ${MEDIA.sm} {
    font-size: 30px;
  }
`;

export function IndicateTitle() {
  const {step} = useHomeStore();

  if (!step.indicateTitle) return null;

  return (
    <Container className='IndicateTitle'>
      <TitleText>{step.indicateTitle}</TitleText>
    </Container>
  );
}
