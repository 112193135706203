import {useMedia} from 'react-use';

import {MEDIA} from '../constants/media';

const getQuery = (str) => str.replace('@media', '');

export function useCustomMedia() {
  // const isLarge = useMedia(getQuery(MEDIA.lg));
  const isMedium = useMedia(getQuery(MEDIA.md));
  const isSmall = useMedia(getQuery(MEDIA.sm));

  return {
    // isLarge,
    isMedium,
    isSmall,
  };
}
