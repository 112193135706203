import useSWR from 'swr';

import {request} from '../../../lib/request';
export function useGetDigitalData() {
  return useSWR('/nftapi/getDigitalData', {shouldRetryOnError: false});
}

export function getDigitalData() {
  return request.get('/nftapi/getDigitalData');
}
