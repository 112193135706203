
export default function () {
    return new Promise((resolove) => {
         import(/*webpackChunkName:'Hweb3dEngine'*/"./Hweb3dEngine")
            .then((res) => {
                console.log(res.default)
           resolove(res.default)
        })
    })
    
}